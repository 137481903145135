import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import ReactHtmlParser from 'react-html-parser'
import { HelmetDatoCms } from 'gatsby-source-datocms'

import Layout from '../components/layout'

const ThanksPage = ({ data: { datoCmsThanksPage: thanks } }) => (
  <Layout>
    <HelmetDatoCms seo={thanks.seoMetaTags} />
    <section
      className="hero is-large is-page-hero"
      style={{
        backgroundImage: `url(${thanks.heroBackgroundImage.fixed.src})`
      }}
    >
      <div className="hero-body">
        <div className="container">
          <h1 className="title has-text-white has-text-weight-normal">
            {thanks.heroTitle}
          </h1>
        </div>
      </div>
    </section>
    <section className="section" style={{ marginBottom: '6rem' }}>
      <div className="columns is-centered">
        <div className="column is-half">
          <div className="content" style={{ marginBottom: '2rem' }}>
            {ReactHtmlParser(thanks.thanksTextNode.childMarkdownRemark.html)}
          </div>
          <div className="buttons">
            <Link to="/" className="button is-black is-outlined">
              Home
            </Link>
            <Link to="/about" className="button is-black is-outlined">
              About Us
            </Link>
            <Link to="/our-ocean" className="button is-black is-outlined">
              Our Ocean
            </Link>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

ThanksPage.propTypes = {
  data: PropTypes.object.isRequired
}

export default ThanksPage

export const query = graphql`
  query ThanksQuery {
    datoCmsThanksPage {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      heroTitle
      heroBackgroundImage {
        fixed(
          width: 1920
          height: 600
          imgixParams: {
            h: "800"
            auto: "compress"
            fm: "pjpg"
            fit: "crop"
            crop: "focalpoint"
            monochrome: "504F4F"
            bri: -20
          }
        ) {
          src
        }
      }
      thanksTextNode {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`
